import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';
import {
  UserRole,
  AddResourceAccess,
  CatalogCode,
  CommandRequestData,
  CreateInvitation,
  CreateTenant,
  EffectiveRole,
  EntitlementData,
  FavoriteApps,
  ITenantDetail,
  JoinTenant,
  PendingService,
  Preferences,
  RemoveAccess,
  ServiceVisibility,
  TenantService,
  TenantUtilityTokens,
  UpdateTenant,
  UploadLogoPreOperation,
  UserFeedback,
  UserNotificationCounts,
  UserPreferences,
  UserTenant,
  Entitlement,
  CreateTenantSuccess,
  ApplicationCard,
  ApplicationCardAction,
  PageResponse,
  TenantUser,
  PageRequest,
} from '../lemans-app.model';

export const emptyAction = createAction('[EMTPY] Empty Action');
export const userLogged = createAction('[USER DATA] User Logged');
export const userLoggedSuccess = createAction('[USER DATA] User Logged');
export const loadUserProfile = createAction('[USER DATA] Load User Profile');
export const updateUserProfile = createAction('[USER DATA] Update User Profile');
export const setUserId = createAction('[USER DATA] Set User ID', props<{ payload: string }>());
export const getUserData = createAction('[USER DATA] Get User Data');
export const getUserPreferences = createAction(
  '[USER DATA] Get User Preferences',
  props<{ isInitialState: boolean; isNewUser: boolean }>(),
);
export const getUserPreferencesSuccess = createAction(
  '[USER DATA] Get User Preferences Success',
  props<{ value: UserPreferences }>(),
);
export const userData = createAction('[USER DATA] Set User Data', props<{ value: UserPreferences }>());
export const getUserRoles = createAction('[USER DATA] Get User Roles');
export const setUserRoles = createAction('[USER DATA] Set User Roles', props<{ value: UserRole[] }>());
export const userNotFound = createAction('[USER DATA] User Not Found');
export const getUserPreferencesFailed = createAction(
  '[USER DATA] Get User Preferences Failed',
  props<{ value: HttpErrorResponse }>(),
);

export const getNotificationCounts = createAction('[USER DATA] Get User Notifications Count');
export const setNotificationCountsSuccess = createAction(
  '[USER DATA] Get User Notifications Count Success',
  props<{ payload: UserNotificationCounts }>(),
);

export const getUserNotifications = createAction('[USER DATA] Get User Notifications');

export const setCurrentLanguage = createAction(
  '[USER DATA] Set Current language',
  props<{ preferredLanguage: string }>(),
);

export const updatePreferredLanguage = createAction(
  '[USER DATA] Update Preffered language',
  props<{ preferredLanguage: string }>(),
);

// system tried to find and set tenant
export const setTenantId = createAction('[TENANT] Set Current Tenant Id', props<{ value: string }>());
// user triggers from org switcher
export const switchTenant = createAction('[TENANT] Tenant Switched', props<{ value: string }>());

export const getTenantLogo = createAction('[TENANT] Get Tenant Logo', props<{ value: string }>());
export const setTenantLogo = createAction('[TENANT] Set Tenant Logo', props<{ value: string }>());

// get tenant api call
export const getTenantDetails = createAction('[TENANT] Get Tenant Details', props<{ value: string }>());

export const setTenantDetails = createAction('[TENANT] Set Current Tenant', props<{ payload?: ITenantDetail }>());

export const resetTenant = createAction('[TENANT] Reset Tenant');

export const getTenantEffectiveRoles = createAction(
  '[TENANT] Get Tenant Effective Roles',
  props<{ payload: string }>(),
);
export const setTenantEffectiveRoles = createAction(
  '[TENANT] Set Tenant Effective Roles',
  props<{ effectiveRoles: EffectiveRole[]; tenantRole: EffectiveRole }>(),
);
export const createTenant = createAction(
  '[TENANT] Create Tenant',
  props<{
    payload: CreateTenant;
    blobImage: Blob;
    servicesVisibilityRequestBody: ServiceVisibility[];
    idempotencyKey: string;
  }>(),
);
export const createTenantSuccess = createAction(
  '[TENANT] Create Tenant Success',
  props<{ payload: CreateTenantSuccess }>(),
);
export const setTenantCreated = createAction('[TENANT] Set Tenant Created', props<{ value: boolean }>());
export const updateTenant = createAction(
  '[TENANT] Update Tenant',
  props<{ payload: UpdateTenant; blobImage?: Blob }>(),
);
export const updateTenantSuccess = createAction('[TENANT] Update Tenant Success');
export const updateTenantNameSuccess = createAction('[TENANT] Update Tenant Name Success', props<{ value: string }>());
export const uploadOrgLogo = createAction(
  '[TENANT] Add Logo',
  props<{ tenantId: string; blobImage: Blob; preOperation: UploadLogoPreOperation }>(),
);
export const uploadOrgLogoSuccess = createAction('[TENANT] Upload org logo Success');
export const getOrgLogo = createAction('[TENANT] Get Logo', props<{ tenantId: string }>());
export const getOrgLogoSuccess = createAction('[TENANT] Get org logo Success');
export const generateInviteCode = createAction('[TENANT] Generate Invite Code', props<{ tenantId: string }>());
export const generateInviteCodeSuccess = createAction('[TENANT] Generate Invite Code Success');
export const joinTenant = createAction(
  '[TENANT] Join Tenant',
  props<{ payload: JoinTenant; isRedirectToDashboard?: boolean }>(),
);
export const joinTenantSuccess = createAction('[TENANT] Join Tenant Success');
export const noLastAccessedTenant = createAction('[TENANT] No Last Accessed Tenant');
export const setPreferences = createAction('[PREFERENCES] Set Preferences', props<{ tenantId: string }>());
export const setPreferencesSuccess = createAction('[PREFERENCES] Set Preferences Success');

//**
export const setFavoriteApps = createAction(
  '[PREFERENCES] Set Favorite apps preferences',
  props<{ favoriteApps: FavoriteApps }>(),
);
export const setFavoriteAppsSuccess = createAction(
  '[PREFERENCES] Set Preferences Success',
  props<{ favoriteApps: FavoriteApps }>(),
);

export const processPreferences = createAction('[PREFERENCES] Process Preferences', props<{ payload?: Preferences }>());
export const processPreferencesSuccess = createAction('[PREFERENCES] Process Preferences Success');
export const createInvitations = createAction(
  '[INVITATION] Create Invitations',
  props<{ payload: CreateInvitation[] }>(),
);
export const createInvitationsSuccess = createAction('[INVITATION] Create Invitations Success');
export const createInvitation = createAction('[INVITATION] Create Invitation', props<{ payload: CreateInvitation }>());
export const createInvitationSuccess = createAction('[INVITATION] Create Invitation Success');
export const resendInvitation = createAction(
  '[INVITATION] Resend Invitation',
  props<{ tenantId: string; invitationId: string }>(),
);
export const resendInvitationSuccess = createAction(
  '[INVITATION] Resend Invitation Success',
  props<{ value: boolean }>(),
);
export const deleteInvitation = createAction(
  '[INVITATION] Delete Invitation',
  props<{ tenantId: string; invitationId: string }>(),
);
export const deleteInvitationSuccess = createAction(
  '[INVITATION] Delete Invitation Success',
  props<{ value: boolean }>(),
);
export const shareFeedback = createAction('[USER DATA] Share User Feedback', props<{ payload: UserFeedback }>());
export const shareFeedbackSuccess = createAction('[USER DATA] Share User Feedback Success');
export const getAllTenantResourceRoles = createAction(
  '[TENANT RESOURCE ROLES] Get Tenant Resource Roles',
  props<{ payload: string }>(),
);
export const setAllTenantResourceRolesSuccess = createAction(
  '[TENANT RESOURCE ROLES] Set Tenant Resource Roles Success',
  props<{ payload: UserRole[] }>(),
);

export const getUserRolesForTenant = createAction(
  '[USER RESOURCE ROLES FOR TENANT] Get User Resource Roles For Tenant',
  props<{ tenantId: string; userId: string }>(),
);

export const setUserRolesForTenantSuccess = createAction(
  '[USER RESOURCE ROLES FOR TENANT] Set User Resource Roles For Tenant Success',
  props<{ payload: UserRole[] }>(),
);

export const getUserResources = createAction('[RESOURCES] Get User Resources', props<{ value: string }>());
export const setUserResourcesSuccess = createAction(
  '[RESOURCES] Set User Resources Success',
  props<{ payload: any }>(),
);

export const getSuggestedTenantByCode = createAction(
  '[TENANT BY CODE] Suggest Tenant By Code',
  props<{ inviteCode: string }>(),
);
export const setSuggestedTenantByCodeSuccess = createAction(
  '[TENANT BY CODE] Suggest Tenant By Code Success',
  props<{ payload: ITenantDetail }>(),
);

export const setNoSuggestedTenantByCodeFound = createAction(
  '[TENANT BY CODE] No Suggested Tenant By Code',
  props<{ payload: boolean }>(),
);

export const createSelectedFakeEntitlements = createAction(
  '[ENTITLEMENTS] Create selected fake entitlements',
  props<{ payload: CatalogCode[]; tenantId: string }>(),
);
export const createSelectedFakeEntitlementsSuccess = createAction(
  '[ENTITLEMENTS] Create selected fake entitlements Success',
  props<{ value: boolean }>(),
);
export const applyRegularEntitlement = createAction(
  '[ENTITLEMENTS] Apply Regular entitlement',
  props<{
    userId: string;
    tenantId: string;
    entitlementData: EntitlementData;
    quantity: number;
    domain: string;
    organizationName?: string;
  }>(),
);
export const applyCustomProvisioningEntitlement = createAction(
  '[ENTITLEMENTS] Apply Custom entitlement',
  props<{ userId: string; tenantId: string; entitlementData: EntitlementData; quantity: number; domain: string }>(),
);
export const applyEntitlementSuccess = createAction('[ENTITLEMENTS] Apply entitlement Success');
export const deallocateEntitlement = createAction(
  '[ENTITLEMENTS] Deallocate entitlement',
  props<{ tenantId: string; entitlement: Entitlement; quantity: number | 'Full'; organizationName?: string }>(),
);
export const updateEntitlements = createAction('[ENTITLEMENTS] update entitlements data', props<{ value: boolean }>());
export const addAccess = createAction(
  '[ADD ACCESS] Add Access',
  props<{ payload: AddResourceAccess[]; tenantId: string; flow?: 'add' | 'edit' | 'approve' }>(),
);
export const approveAccess = createAction(
  '[APPROVE ACCESS] Approve Access',
  props<{ payload: AddResourceAccess; userData: any }>(),
);
export const addSingleAccess = createAction(
  '[ADD ACCESS] Add Single Access',
  props<{ value: AddResourceAccess; tenantId: string; flow?: 'add' | 'edit' | 'approve' }>(),
);
export const approveUser = createAction(
  '[APPROVE USER] Approve User',
  props<{ payload: AddResourceAccess; id: string }>(),
);
export const approveUserSuccess = createAction('[APPROVE USER] Approve User Success', props<{ value: boolean }>());
export const addSingleAccessSuccess = createAction('[ADD ACCESS] Add Single Access Success');
export const dismissUser = createAction('[APPROVE USER] Dismiss User', props<{ value: string; tenantId: string }>());
export const dismissUserSuccess = createAction('[APPROVE USER] Dismiss User Success', props<{ value: boolean }>());
export const setEditUserDetails = createAction(
  '[EDIT USER DETAILS] Edit User Details',
  props<{ payload: Partial<UserRole> }>(),
);
export const removeMulitpleAccess = createAction(
  '[REMOVE ACCESS] Remove Multiple Access',
  props<{ payload: RemoveAccess[] }>(),
);
export const removeAccess = createAction('[REMOVE ACCESS] Remove Access', props<{ payload: RemoveAccess }>());
export const removeAccessSuccess = createAction('[REMOVE ACCESS] Remove Access Success', props<{ value: boolean }>());

export const acceptInvitation = createAction(
  '[ACCEPT INVITATION] Accept Invitation',
  props<{ invitationId: string }>(),
);

export const getServicesVisibility = createAction('[SERVICES] Get All Services Visibility', props<{ value: string }>());
export const setServicesVisibilitySuccess = createAction(
  '[SERVICES VISIBILITY] Get Services Visibility Success',
  props<{ payload: ServiceVisibility[] }>(),
);
export const updateServicesVisibility = createAction(
  '[SERVICES VISIBILITY] Update Services Visibility',
  props<{ tenantId: string; payload: ServiceVisibility[] }>(),
);

export const getTenantServices = createAction(
  '[TENANT SERVICES] Get Current Tenant Services',
  props<{ payload: UserTenant }>(),
);
export const setTenantServices = createAction(
  '[TENANT SERVICES] Set Current Tenant Services Success',
  props<{ payload: TenantService[] }>(),
);

export const getTenantUtilityTokens = createAction(
  '[TENANT UTILITY TOKENS] Get Current Tenant Utility Tokens and Entitlements',
  props<{ value: string }>(),
);

export const setTenantUtilityTokens = createAction(
  '[TENANT UTILITY TOKENS] Set Current Tenant Utility Tokens',
  props<{ payload: TenantUtilityTokens }>(),
);

export const openIframe = createAction(
  '[CUSTOM PROVISIONING] Open Iframe for custom Provisioning',
  props<{ payload: PendingService[] }>(),
);
export const initializeCustomProvisioning = createAction(
  '[CUSTOM PROVISIONING] Start Custom Provisioning',
  props<{ payload: EntitlementData }>(),
);

export const setCurrentProvisioningData = createAction(
  '[CUSTOM PROVISIONING] Set Current Provisioning Data',
  props<{ payload: any }>(),
);

export const waitForCustomProvisioningComplete = createAction(
  '[CUSTOM PROVISIONING] Wait For Custom Provisioning Complete',
  props<{ payload: EntitlementData }>(),
);
export const customProvisioningCompleted = createAction('[CUSTOM PROVISIONING] Custom Provisioning Completed');
export const leaveCustomProvisioning = createAction('[CUSTOM PROVISIONING] Leave Custom Provisioning');

export const applyEntitlementCancelled = createAction('[CUSTOM PROVISIONING] Custom Provisioning Cancelled');

export const waitForCommadRequestResponse = createAction(
  '[COMMAND REQUEST] Wait For Command Request Response',
  props<{ payload: CommandRequestData }>(),
);

export const cardClicked = createAction(
  '[CARD CLICKED] Appliction card clicked',
  props<{ action: ApplicationCardAction; card: ApplicationCard }>(),
);

export const getTenantUsers = createAction(
  '[TENANT] Get selected users of a Tenant',
  props<{ payload: PageRequest }>(),
);

export const setTenantUsers = createAction(
  '[TENANT] Set selected users of a Tenant',
  props<{ selectedTenantUsers: PageResponse<TenantUser> }>(),
);
